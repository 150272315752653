/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import {
  Container,
  //List,
  Segment,
  //Grid,
  //GridRow,
  //GridColumn,
} from 'semantic-ui-react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
//import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

import { useGoogleAnalytics } from 'volto-google-analytics';
const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  useGoogleAnalytics();
  return (
    <Segment
      role="contentinfo"
      vertical
      padded
      inverted
      id="footer"
      aria-label="Footer"
    >
      <Container textAlign="center">
        <FormattedMessage
          id="{copyright} Yorktown Foundation for Freedom {current_year}"
          defaultMessage="{copyright} Yorktown Foundation for Freedom {current_year}"
          values={{
            copyright: (
              <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
            ),
            current_year: new Date().getFullYear(),
          }}
        />
        <br />
        <p>
          Images courtesy of{' '}
          <a href="https://unsplash.com/" target="_blank" rel="noreferrer">
            Unsplash
          </a>
        </p>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
